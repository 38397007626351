import Vue from "vue";
import VueRouter from "vue-router";
import Layout from '@/layout'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/HomeView'),
        meta: { title: '网站首页' }
      },
      {
        path: 'about',
        name: 'about',
        component: () => import('@/views/About/About'),
        meta: { title: '关于我们' }
      },
      {
        path: 'product',
        name: 'product',
        component: () => import('@/views/Product/Product'),
        meta: { title: '产品中心' }
      },
      {
        path: 'product/details',
        name: 'productDetails',
        component: () => import('@/views/Product/details'),
        meta: { title: '产品中心' }
      },
      {
        path: 'news',
        name: 'news',
        component: () => import('@/views/News/News'),
        meta: { title: '新闻中心' }
      },
      {
        path: 'news/details',
        name: 'newsDetails',
        component: () => import('@/views/News/details'),
        meta: { title: '新闻中心123' }
      },
      {
        path: 'contact',
        name: 'contact',
        component: () => import('@/views/Contact/Contact'),
        meta: { title: '联系我们' }
      },
      {
        path: 'after',
        name: 'after',
        component: () => import('@/views/After/After'),
        meta: { title: '服务中心' }
      },
      {
        path: 'information',
        name: 'information',
        component: () => import('@/views/Information/Information'),
        meta: { title: '资料下载' }
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const VueRouterPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(to) {

  return VueRouterPush.call(this, to).catch(err => err)

}

export default router;
