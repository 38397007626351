<template>
  <div>
    <div class="topbar">
      <div @click="toOld" class="oldgw">
        <!-- <router-link to="http://www.jszhuiguang.cn/"> -->
        跳转到旧版官网
      </div>
      <div class="topcontent">
        <router-link to="/">
          <div class="logo">
            <img src="@/assets/logo.png" />
          </div>
        </router-link>
        <div class="navs">
          <div class="nav_list">
            <router-link to="/home">
              <div class="nav_1">
                <span>网站首页</span>
                <div class="nav_2"></div>
              </div>
              <div class="nav_3">HOME</div>
            </router-link>
          </div>
          <div class="nav_list">
            <router-link to="/about">
              <div class="nav_1">
                <span>关于我们</span>
                <div class="nav_2"></div>
              </div>
              <div class="nav_3">ABOUT</div>
            </router-link>
          </div>
          <div class="nav_list">
            <router-link to="/product">
              <div class="nav_1">
                <span>产品中心</span>
                <div class="nav_2"></div>
              </div>
              <div class="nav_3">PRODUCT</div>
            </router-link>
          </div>
          <div class="nav_list">
            <router-link to="/news">
              <div class="nav_1">
                <span>新闻中心</span>
                <div class="nav_2"></div>
              </div>
              <div class="nav_3">NEWS</div>
            </router-link>
          </div>
          <div class="nav_list">
            <div @click="goTaobao" class="shop">
              <div class="nav_1">
                <span>官方淘宝</span>
                <div class="nav_2"></div>
              </div>
              <div class="nav_3">SHOP</div>
            </div>
          </div>
          <div class="nav_list">
            <router-link to="/contact">
              <div class="nav_1">
                <span>联系我们</span>
                <div class="nav_2"></div>
              </div>
              <div class="nav_3">CONTACT</div>
            </router-link>
          </div>
          <div class="nav_list">
            <router-link to="/after">
              <div class="nav_1">
                <span>服务中心</span>
                <div class="nav_2"></div>
              </div>
              <div class="nav_3">SERVE</div>
            </router-link>
          </div>
          <div v-if="showObj.information" class="nav_list">
            <router-link to="/information">
              <div class="nav_1">
                <span>资料下载</span>
                <div class="nav_2"></div>
              </div>
              <div class="nav_3">INFORMATION</div>
            </router-link>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'topBar',
  // 监听,当路由发生变化的时候执行
  watch: {
    $route: {
      handler: function (val, oldVal) {
        document.documentElement.scrollTop = 0
      },
      // 深度观察监听
      deep: true,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(['showObj'])
  },
  created() {
    this.checkDevice()
  },
  mounted() {
    console.log(this.showObj, '123')
  },
  methods: {
    goTaobao() {
      window.open('https://shop243139519.taobao.com/?spm=a1z10.1-c-s.0.0.343a4f27cZkT6M', '_blank')
    },
    checkDevice() {
      if (this.isMobileDevice()) {
        // window.open('http://123.207.31.133/m', '_self')
        window.open('http://www.zg-intelligence.com/m', '_self')
        // 在这里执行移动设备相关的逻辑
      } else {
        console.log("这是PC设备")
        // 在这里执行PC设备相关的逻辑
      }
    },
    isMobileDevice() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    toOld() {
      window.open('http://www.jszhuiguang.cn/', '_blank')
    }
  },
}
</script>

<style lang="scss" scoped>
.topbar {
  width: 100%;
  height: 72px;
  background: #030811;
  padding: 10px 0;
  position: relative;

  .oldgw {
    top: 20px;
    left: 20px;
    color: #fff;
    position: absolute;
    cursor: pointer;
  }

  .topcontent {
    margin: 0 auto;
    width: 1200px;
    height: 55px;
    display: flex;
    flex-wrap: nowrap;

    .logo {
      margin-right: 148px;
      height: 100%;
      width: 140px;
      display: flex;
      align-items: center;

      img {
        width: 140px;
        height: 48px;
      }

      cursor: pointer;
    }

    .navs {
      flex: 1;
      height: 100%;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;

      .nav_list {
        width: 88px;
        height: 39px;
        margin-right: 68px;
        font-size: 12px;

        a {
          display: block;
          width: 100%;
          height: 100%;
          color: rgba(255, 255, 255, 0.55);
          text-decoration: none;
        }

        .shop {
          display: block;
          width: 100%;
          height: 100%;
          color: rgba(255, 255, 255, 0.55);
          cursor: pointer;
        }

        .nav_1 {
          height: 20px;
          line-height: 20px;
          text-align: center;
          position: relative;
          font-size: 20px;
          .nav_2 {
            position: absolute;
            left: 0;
            right: 0;
            bottom: -2px;
            height: 4px;
          }
        }

        .nav_3 {
          margin-top: 1px;
          height: 18px;
          line-height: 18px;
          text-align: center;
          display: none;
          font-size: 12px;
        }

        .router-link-active {
          color: #fff;

          .nav_2 {
            background-color: rgba(27,36,255,0.8);
          }

          .nav_3 {
            display: block;
          }
        }
      }

      .nav_list:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
