<template>
  <div class="main">
    <TopBar />
    <transition name="fade-transform" mode="out-in">
      <router-view :key="key" />
    </transition>
    <Foot />
  </div>
</template>

<script>
import TopBar from './components/TopBar.vue'
import Foot from './components/Foot.vue'

export default {
  name: 'Layout',
  components: {
    TopBar,
    Foot,
  },
  computed: {
    key() {
      return this.$route.path
    },
  },
  created() {
    this.$store.dispatch('fetchShowObj') // 触发异步操作，比如请求接口
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
/* fade */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.28s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
/* fade-transform */
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.5s;
}
.fade-transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}
.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.main::-webkit-scrollbar {
  width: 3px; /* Adjust the width as needed */
}
/* Customize the scrollbar thumb */
.main::-webkit-scrollbar-thumb {
  background-color: #504e7e; /* Change the color of the thumb */
  border-radius: 4px; /* Round the edges of the thumb */
}
/* Customize the scrollbar thumb on hover */
.main::-webkit-scrollbar-thumb:hover {
  background-color: #504e7e; /* Change the color on hover */
}
</style>
