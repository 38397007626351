import Vue from "vue";
import Vuex from "vuex";
import { MODULE_GET } from '@/api'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showObj: {}
  },
  getters: {
    showObj: state => state.showObj
  },
  mutations: {
    changeShowObj(state, showObj) {
      state.showObj = showObj
    }
  },
  actions: {
    async fetchShowObj({ commit }) {
      try {
        // 发起异步请求获取数据
        const response = await Vue.prototype.$http.get(MODULE_GET)
        if (response.code === 200) {
          let data = response.result[0] // 修改获取数据的方式
          commit('changeShowObj', data)
        } 
      } catch (error) {
        console.error('Error fetching initial data:', error);
      }
    }
  },
  modules: {},
});
