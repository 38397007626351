// 留言
export const MESSAGE_ADD = 'userMessage/add' // 查询留言

export const ADD_AFTER = 'afterSale/add ' // 提交反馈信息

export const GET_PRODUCT_CATEGORY = 'productClassify/productClassifies' // 获取产品分类信息
export const GET_PRODUCT_MODEL = 'productModel/productModels' // 获取产品型号信息
export const PRODUCT_DETAILS = 'productModel/productModel' // 获取产品型号信息

export const NEWS_LIST = 'newsInfo/newsInfos' // 新闻列表
export const NEWS_DETAILS = 'newsInfo/newsInfo' // 新闻详情
// export const LIST_CERTIFICATE = 'api/certificate/list' // 证书列表

export const CERTIFICATE_CCC = 'credential/cccS' // 3C证书
export const CERTIFICATE_FANGBAO = 'credential/explosionProofs' // 防爆证书
export const CERTIFICATE_ZHUANLI = 'credential/patents' // 专利证书
export const CERTIFICATE_QITA = 'credential/otherCertificates' // 其他证书
export const CERTIFICATE_ANBIAO = 'credential/securityStandards' // 安标证书
export const CERTIFICATE_RUANZHU = 'credential/softwareWorks' // 安标证书

// 资料管理
export const INFORMATION_LIST = 'information/information' // 资料列表

export const MODULE_GET = 'information/showModule/find' // 模块设置