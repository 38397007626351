import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
console.log(process.env)
if (process.env.NODE_ENV === "production") {
  localStorage.setItem('apiUrl', 'http://139.224.119.168/')
}else {
  // localStorage.setItem('apiUrl', 'http://192.168.2.3/')
  localStorage.setItem('apiUrl', 'http://139.224.119.168/')
}
// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  baseURL: '', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache'
  },
  loading: true,
  timeout: 5000 // request timeout
})
service.defaults.validateStatus = () => true

// request interceptor
service.interceptors.request.use(
  config => {
    config.url = localStorage.getItem('apiUrl') + config.url
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    if (res.code !== 200) {
      // Message({
      //   message: '稍后重试',
      //   type: 'error',
      //   duration: 5 * 1000
      // })
    } else {
      return res
    }
  },
  error => {
    if (error.response) {
      // 处理服务器响应错误
    } else if (error.request) {
      // 处理请求发送成功但没有响应的情况
    } else {
      // 处理其他网络错误
      console.error('Network Error: ', error.message)
    }
    return Promise.reject(error)
  }
)

export default service
